import axios from 'axios';
import toast from 'react-hot-toast';

import { ControllerService } from '@features/controllers/services';
import {
  FlowsService,
  RecipeFlowsService,
} from '@features/cultivation-flows/services';
import { GroupsService } from '@features/cultivation-groups/services';
import { CultivationRecipesService } from '@features/cultivation-recipes/services';
import {
  DevicesService,
  DeviceStatusesService,
} from '@features/devices/services';
import {
  EnvironmentalEventsService,
  EnvironmentalControlZonesService,
  EnvironmentalRecipesService,
  EnvironmentalZonesService,
  EnvironmentsHistoryService,
  EnvironmentsService,
} from '@features/environmental/services';
import { FacilitiesService } from '@features/facilities/services';
import { FeedbackControlZonesHistoryService } from '@features/feedback/services';
import {
  IrrigationEventsService,
  IrrigationHistoryService,
  IrrigationRecipesService,
  IrrigationTypesService,
  IrrigationZonesService,
  IrrigationZonesHistoryService,
} from '@features/irrigation/services';
import { LightZonesService } from '@features/light/services';
import { PhasesService } from '@features/phases/services';
import { RoomsService, RoomTypesService } from '@features/rooms/services';
import { StrainsService } from '@features/strains/services';
import { TimeControlZonesService } from '@features/time/services';
import { TraysService } from '@features/trays/services';
import {
  WaterSensorsService,
  WaterQualityZonesService,
  WaterSensorInterfacesService,
  WaterSensorParametersService,
  WaterSensorsHistoryService,
} from '@features/water/services';

import { auth } from '../utils/firebase';

export const API_URL = {
  prod: 'https://us-central1-gg-io-prod.cloudfunctions.net/v1',
  dev: 'https://us-central1-gg-io-prod.cloudfunctions.net/v1',
  local: 'http://localhost:5001/gg-io-prod/us-central1/v1',
  'local-prod': 'http://localhost:5001/gg-io-prod/us-central1/v1',
}[process.env.REACT_APP_ENV];

/**
 * Make sure this is only called once per application load
 */
export const initAPIService = () => {
  axios.defaults.baseURL = API_URL;

  axios.interceptors.request.use(
    async config => {
      if (!auth.currentUser) {
        return config;
      }
      config.headers.Authorization = await auth.currentUser.getIdToken();
      config.headers['Facility-Id'] = localStorage.getItem('facilityId');
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 500) {
      toast(
        'Issue with the server, please wait a few minutes and try your request again.',
        {
          style: {
            background: '#E57373',
          },
        },
      );
    }
    return error;
  },
);

export const APIService = {
  CONTROLLERS: ControllerService,
  DEVICES: DevicesService,
  DEVICE_STATUS: DeviceStatusesService,
  FACILITIES: FacilitiesService,
  IRRIGATION_ZONES: IrrigationZonesService,
  ROOMS: RoomsService,
  TYPE_ROOM: RoomTypesService,
  TRAYS: TraysService,
  TIME_CONTROL_ZONES: TimeControlZonesService,
  ENVIRONMENTS: EnvironmentsService,
  ENVIRONMENTS_HISTORY: EnvironmentsHistoryService,
  ENVIRONMENTAL_ZONES: EnvironmentalZonesService,
  ENVIRONMENTAL_CONTROL_ZONES: EnvironmentalControlZonesService,
  IRRIGATION_LOGS_HISTORY: IrrigationHistoryService,
  IRRIGATION_ZONES_HISTORY: IrrigationZonesHistoryService,
  RECIPE_CULTIVATION_CYCLE: CultivationRecipesService,
  PHASES: PhasesService,
  STRAINS: StrainsService,
  ENVIRONMENTAL_RECIPE: EnvironmentalRecipesService,
  IRRIGATION_RECIPE: IrrigationRecipesService,
  RECIPE_FLOW: RecipeFlowsService,
  LIGHT_ZONES: LightZonesService,
  CULTIVATION_FLOW: FlowsService,
  CULTIVATION_GROUPS: GroupsService,
  ENVIRONMENTAL_EVENT: EnvironmentalEventsService,
  IRRIGATION_EVENT: IrrigationEventsService,
  TYPE_IRRIGATION: IrrigationTypesService,
  FEEDBACK_CONTROL_ZONES_HISTORY: FeedbackControlZonesHistoryService,
  WATER_SENSORS: WaterSensorsService,
  WATER_SENSOR_INTERFACES: WaterSensorInterfacesService,
  WATER_SENSORS_HISTORY: WaterSensorsHistoryService,
  WATER_QUALITY_ZONES: WaterQualityZonesService,
  WATER_SENSOR_PARAMETERS: WaterSensorParametersService,
};
